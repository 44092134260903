@font-face {
  font-family: Jost;
  src: url(./assets/fonts/Jost-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Jost;
  src: url(./assets/fonts/Jost-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Jost;
  src: url(./assets/fonts/Jost-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Jost;
  src: url(./assets/fonts/Jost-Bold.ttf);
  font-weight: 700;
}
