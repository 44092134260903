@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./fonts.css";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 1vw;
}

body {
  padding: 0 5rem;
  display: flex;
  flex-direction: column;
  font-family: Jost, sans-serif;
  font-weight: 300;
  @apply text-main-black;
  @apply bg-main-white;
}

h1 {
  -webkit-text-stroke: 0.3rem #414143;
  text-stroke: 0.3rem #414143;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
textarea {
  @apply transition-colors ease-linear duration-300 hover:border-main-green;
}

input:focus-visible,
textarea:focus-visible {
  outline: none;
  @apply border-main-green;
}

.text-link {
  @apply transition-colors ease-in-out duration-300 hover:text-main-green;
}

.text-link .arrow {
  @apply transition-transform ease-in-out duration-300;
}

.text-link:hover .arrow {
  @apply -translate-y-[10%] translate-x-[10%];
}

.text-link .arrow path {
  @apply transition-colors ease-in-out duration-300;
}

.text-link:hover .arrow path {
  @apply fill-main-green;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 3vw;
  }

  body {
    padding: 0 2rem;
  }

  h1 {
    -webkit-text-stroke: 0.1rem #414143;
    text-stroke: 0.1rem #414143;
  }
}
